

<template>
  <div id="box" class="home-container">

    <div class="box_content">
      <div class="box_content_title">修改用户信息</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="4" style="color: transparent">1</el-col>
          <el-col :span="8">
            <el-form-item label="单位名称" prop="company">
              <el-input v-model="ruleForm.company" placeholder="请输入单位名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="信用代码" prop="yscode">
              <el-input v-model="ruleForm.yscode"  placeholder="请输入信用代码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="4" style="color: transparent">1</el-col>
          <el-col :span="8">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email"  placeholder="请输入邮箱信息"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="密码" prop="password">
              <el-input v-model="ruleForm.password" maxlength="16" show-password placeholder="请输入密码,不需要修改可不填！"></el-input>
            </el-form-item>
          </el-col>
        </el-row>



        <div style="display: flex;flex-direction: column;margin-left: 0;">
          <el-button style="width: 520px;background:#f39800;border-color: #f39800;margin: 10px auto;" type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
          <el-button style="margin: 10px auto;width: 520px" @click="resetForm('ruleForm')">返回列表</el-button>
        </div>
      </el-form>
    </div>

  </div>
</template>

<script>
import {
  Company_getInfo,
  conf_list,
  mallOrderdata_getrestnum,
  mallOrderdata_update_user_info, order_orderlist,
  user_register
} from "@/api/manage";
import {removeToken} from "@/utils/auth";
export default {
  data() {
    var validatePass1 = (rule, value, callback) => {
      var regex = /^1[3456789]\d{9}$/
      if (value === '') {
        callback(new Error('请输入手机号！'));
      } else if(!regex.test(value)){
        callback(new Error('手机号不正确！'));
      }else{
        callback()
      }
    };
    var validatePass2 = (rule, value, callback) => {
      var pattern = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/
      if (value === '') {
        callback(new Error('请输入邮箱！'))
      } else if(!pattern.test(value)){
        callback(new Error('请检查邮箱格式！'))
      }else{
        callback();
      }
    };
    return {
      httplogo:'',
      fontSize: 26, // 默认字体大小:
      ruleForm: {
        company:'',
        yscode:'',
        email:'',
        password:'',
      },
      rules: {

      }
    }
  },
  created() {
    this.setFontSize(); // 组件创建时设置字体大小
    window.addEventListener('resize', this.setFontSize); // 监听窗口大小变化
    this.httplogo = "/imgs/" + window.location.hostname + "/logo.png"
    var configdata =  JSON.parse(localStorage.getItem('configdata'))
    this.configdata = configdata
    this.province = configdata.province
    Company_getInfo({}).then(response => {
      // console.log(response.user,'response+++++++++')
      this.ruleForm.company = response.user.company
      this.ruleForm.yscode =  response.user.yscode
      this.ruleForm.email =  response.user.email
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.setFontSize); // 移除监听器
  },
  methods: {
    home(){
      this.$router.push("/")
    },
    setFontSize() {
      const baseFontSize = 20; // 基准字体大小
      const maxFontSize = 28; // 最大字体大小
      const minFontSize = 25; // 最小字体大小
      const screenWidth = window.innerWidth; // 获取屏幕宽度
      const widthRate = screenWidth / 1920; // 宽度缩放比率（假设1920px设计稿宽度）

      this.fontSize = Math.min(maxFontSize, Math.max(minFontSize, baseFontSize * widthRate));
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var value = this.ruleForm.password
          var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-+.]).{6,16}$/;
          if(value != '' && !regex.test(value)){
            this.$message({
              message: '请检查密码格式！（最少包含一位大写英文字符+一位小写英文字符+一位数字+长度6-16为之间+一位特殊字符）',
              type: 'warning'
            });
            return false
          }else{
            mallOrderdata_update_user_info(this.ruleForm).then(response => {
              this.$confirm('用户信息修改完成，需要重新登录！确认前往？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                removeToken()
                localStorage.removeItem("setToken");
                localStorage.removeItem("companyinfo");
                this.$router.push("/login")
              }).catch(() => {
                removeToken()
                localStorage.removeItem("setToken");
                localStorage.removeItem("companyinfo");
                this.$router.push("/")
              });
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }

      });
    },
    resetForm(formName) {
      this.$router.push("/orderlist");
      // this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped lang="css">
#box {
  background-color:#fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //height: 100vh;
}

.box_banner_logo{
  display: flex;
  flex-direction: row;
  width:100%;
  height: 100px;
  font-weight: bold;
  line-height:80px;
  padding: 10px 10%;
  box-sizing: border-box;

}



.box_content{
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  box-sizing: border-box;
}

.box_content_title{
  display: block;
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  font-family: cursive;
  margin-bottom: 50px;

}

</style>